import * as React from 'react'
import { FormattedMessage } from 'react-intl';


import IndexLayout from '../layouts'
import LilaBook from '../components/LilaBook'
import LilaSection from '../components/LilaSection'
import StickyNav from '../components/nav/StickyNav'
import styled from '@emotion/styled'
var Scroll = require('react-scroll');
var Element = Scroll.Element;



const Chapter = styled.div`
width:100%;
display:flex;
padding-left: 8rem;
padding-right: 12rem;
padding-top: 4rem;
text-align:left;

 @media screen and (max-width: 1199px) {
padding-left: 10%;
padding-right: 10%;

}
`

const ChapterText = styled.div`
width:70%;
margin-bottom:4rem;

 @media screen and (max-width: 1199px) {

width:100%;

}


`

const SubP = styled.p`
text-align: left;
margin-top:1.2rem;
margin-bottom:1rem;
letter-spacing:1px;

`

const P = styled.p`
text-align: left;
letter-spacing:1px;
font-size:1rem !important;
margin-bottom:0px;
padding-bottom:0px;
`


const H1 = styled.h1`
font-size:2rem;
`

const Widerruf = styled.div`
border: 1px solid var(--border);
padding:20px;
`

const Agb = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <LilaBook title={'AGB'} subTitle1={'Allgemeine'} subTitle2={'Geschäftsbedingungen'}/>


    <Element id="chOne"/>
    <div id={'section-1'}>
    <LilaSection id={'first'} title={''}></LilaSection>
     <Chapter id={'chapter1'}>
        <StickyNav start={150} end={'#chapter1'}/>
        <ChapterText>
          <H1><FormattedMessage id={'agb_header_1'}/> </H1>
          <SubP> <FormattedMessage id={'agb_paragraph_1'}/> </SubP>

          <P> <FormattedMessage id={'agb_1'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_2'}/> </SubP>
          <P><FormattedMessage id={'agb_2'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_3'}/> </SubP>
          <P> <FormattedMessage id={'agb_3'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_4'}/> </SubP>
          <P> <FormattedMessage id={'agb_4'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_5'}/> </SubP>
          <P><FormattedMessage id={'agb_5'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_6'}/> </SubP>
          <P><FormattedMessage id={'agb_6'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_7'}/> </SubP>
            <P><FormattedMessage id={'agb_7_1'}/></P>
            <P><FormattedMessage id={'agb_7_2'}/></P>
            <P><FormattedMessage id={'agb_7_3'}/></P>



          <SubP> <FormattedMessage id={'agb_paragraph_8'}/> </SubP>
            <P><FormattedMessage id={'agb_8'}/></P>


        </ChapterText>
          </Chapter>
    </div>

    <Element id="chTwo"/>
    <div id={'section-2'}>
    <LilaSection title={''} id={''}></LilaSection>
    <Chapter id={'chapter2'}>
        <StickyNav start={150} end={'#chapter2'}/>
        <ChapterText>
          <H1><FormattedMessage id={'agb_header_2'}/> </H1>

          <SubP> <FormattedMessage id={'agb_paragraph_8'}/> </SubP>
          <P><FormattedMessage id={'agb_8'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_9'}/> </SubP>
          <P><FormattedMessage id={'agb_9_1'}/></P>
          <P><FormattedMessage id={'agb_9_2'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_10'}/> </SubP>
          <SubP> <FormattedMessage id={'agb_paragraph_10_1'}/> </SubP>
       <Widerruf>
         <SubP> <FormattedMessage id={'agb_paragraph_10_1_1'}/> </SubP>
         <SubP> <FormattedMessage id={'agb_sub_1'}/> </SubP>
         <P><FormattedMessage id={'agb_10'}/></P>
         <SubP> <FormattedMessage id={'agb_sub_2'}/> </SubP>
         <P><FormattedMessage id={'agb_10_1'}/></P>

       </Widerruf>

          <SubP> <FormattedMessage id={'agb_paragraph_10_1_2'}/> </SubP>
          <P><FormattedMessage id={'agb_10_1_2'}/></P>

          Button

          <SubP> <FormattedMessage id={'agb_paragraph_10_1_3'}/> </SubP>
          <P><FormattedMessage id={'agb_10_1_3'}/></P>
          <P><FormattedMessage id={'agb_10_1_4'}/></P>
        </ChapterText>
          </Chapter>

    </div>


    <Element id="chThree"/>
    <div id={'section-3'}>
    <LilaSection title={''} id={''}></LilaSection>
    <Chapter id={'chapter3'}>
        <StickyNav start={150} end={'#chapter3'}/>
        <ChapterText>
          <H1> <FormattedMessage id={'agb_header_3'}/></H1>
          <SubP> <FormattedMessage id={'agb_paragraph_12'}/> </SubP>
          <P><FormattedMessage id={'agb_12'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_13'}/> </SubP>
          <P><FormattedMessage id={'agb_13_1'}/></P>
          <P><FormattedMessage id={'agb_13_2'}/></P>
          <P><FormattedMessage id={'agb_13_3'}/></P>
          <P><FormattedMessage id={'agb_13_4'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_14'}/> </SubP>
          <P><FormattedMessage id={'agb_14_1'}/></P>
          <P><FormattedMessage id={'agb_14_2'}/></P>
          <P><FormattedMessage id={'agb_14_3'}/></P>
          <P><FormattedMessage id={'agb_14_4'}/></P>

          <SubP> <FormattedMessage id={'agb_paragraph_15'}/> </SubP>
          <P><FormattedMessage id={'agb_15'}/></P>

        </ChapterText>
          </Chapter>
    </div>


  </IndexLayout>
)

export default Agb
